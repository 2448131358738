import { ComponentProps, useEffect, useState, useCallback } from "react";
import styled from "styled-components/macro";
import { BoxCard, BoxCardContent } from "../components/BoxCard";
import logo from "../assets/logo.png";
import loginBackground from "../assets/login-background.jpg";
import { SERVICE_HOST, useView } from "../services";
import { parse as cookieParse, serialize as cookieSerialize } from "cookie";
import { HeadingCard } from "../components/HeadingCard";
import { Button } from "../components/Button";
import { BoxRow, BoxRowCenter } from "../components/BoxRow";
import {
  MostlyFluidLayoutApart,
  MostlyFluidLayoutRight,
} from "../components/MostlyFluidLayout";
import { BoxBlockCenter } from "../components/BoxBlock";
import { MostlyFluidSpacedVertical16px } from "../components/MostlyFluidSpaced";
import { BoxMarginTop16px } from "../components/BoxMargin";
import { Toast } from "../components/Toast";
import { TextBaseLight } from "../components/Text";
import { FieldText } from "../components/FieldText";
import { FieldSelect } from "../components/FieldSelect";

const Page = styled.div`
  box-sizing: border-box;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("${loginBackground}");
  background-size: cover;
  padding: 32px;
`;

const LoginContainer = styled.div`
  flex: 0 1 500px;
`;

const Logo = styled.img`
  width: 32px;
`;

export const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const [region, setRegion] = useState<string | undefined>(cookieParse(document.cookie)["region-cookie"]);
  const [region, setRegion] = useState<string | undefined>("reg1onlnkmlsp7kq");
  const [isDisabled, setIsDisabled] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);

  const { RegionOptions } = useView("RegionOptions", {});

  useEffect(() => {
    if (RegionOptions.status === "ok") {
      setIsDisabled(false);
    } else if (RegionOptions.status === "error") {
      setError("Login is currently unavailable. Try again later");
      setIsDisabled(true);
    }
  }, [RegionOptions.status]);

  const onSubmit: ComponentProps<"form">["onSubmit"] = useCallback(
    async (e) => {
      if (isDisabled) {
        return; // we're already submitting something
      }

      setIsDisabled(true);
      setError(undefined);

      fetch(`${SERVICE_HOST}/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          regionKey: region,
          username,
          password,
        }),
        credentials: "include",
      })
        .then(({ status }) => {
          if (status >= 200 && status <= 204) {
            const regionCookie = cookieSerialize("region-cookie", region!, {
              path: "/",
            });
            document.cookie = regionCookie;
            window.location.reload();
          } else if (status >= 400 && status <= 401) {
            setError("Incorrect username or password");
          } else if (status === 503) {
            setError("Login is unavailable. Try again later");
          } else {
            setError("Something is wrong. Try again later");
          }
        })
        .catch(() => setError("Something went wrong - try again later"))
        .finally(() => setIsDisabled(false));

      e.preventDefault();
      return false;
    },
    [isDisabled, password, region, username]
  );

  const hiddenRegions = ["Deutschland", "Norge", "Poland", "Schweiz", "Sverige", "The Netherlands"];
  const filteredRegionOptions = RegionOptions.status === "ok"
      ? RegionOptions.result.filter(({ regionKey, title }) => !hiddenRegions.includes(title))
      : [];

  return (
    <Page>
      <LoginContainer>
        <MostlyFluidSpacedVertical16px>
          {error !== undefined && (
            <Toast type="element" color="error">
              <TextBaseLight>
                <strong>Error:</strong> {error}
              </TextBaseLight>
            </Toast>
          )}

          <BoxRow>
            <BoxCard>
              <form onSubmit={onSubmit}>
                <BoxCardContent>
                  <BoxRowCenter>
                    <MostlyFluidLayoutApart>
                      <BoxBlockCenter>
                        <HeadingCard tag="h3">Sinitone</HeadingCard>
                      </BoxBlockCenter>
                      <Logo src={logo} alt="" />
                    </MostlyFluidLayoutApart>
                  </BoxRowCenter>
                </BoxCardContent>

                <BoxCardContent>
                  <MostlyFluidSpacedVertical16px>
                    <FieldSelect
                      disabled={isDisabled}
                      label="Region"
                      placeholder="Select region"
                      // options={
                      //   RegionOptions.status === "ok"
                      //     ? RegionOptions.result.map(
                      //         ({ regionKey, title }) => ({
                      //           label: title,
                      //           value: regionKey,
                      //         })
                      //       )
                      //     : []
                      // }
                      options={filteredRegionOptions.map(({ regionKey, title }) => ({
                                label: title,
                                value: regionKey,
                      }))}
                      onChange={setRegion}
                      value={region}
                    />
                    <FieldText
                      disabled={isDisabled}
                      type="text"
                      label="Username"
                      placeholder="Enter username"
                      onChange={setUsername}
                      value={username}
                      autoComplete="username"
                    />
                    <FieldText
                      disabled={isDisabled}
                      type="password"
                      label="Password"
                      placeholder="Enter password"
                      onChange={setPassword}
                      value={password}
                      autoComplete="current-password"
                    />
                    <BoxMarginTop16px>
                      <BoxRow>
                        <MostlyFluidLayoutRight>
                          <Button disabled={isDisabled} type="submit">
                            Log in
                          </Button>
                        </MostlyFluidLayoutRight>
                      </BoxRow>
                    </BoxMarginTop16px>
                  </MostlyFluidSpacedVertical16px>
                </BoxCardContent>
              </form>
            </BoxCard>
          </BoxRow>
        </MostlyFluidSpacedVertical16px>
      </LoginContainer>
    </Page>
  );
};
