import {
  setupBoundedContext,
  ViewRequest,
  ScenarioRequest,
  FormRequest,
} from "bounded-context-react";
import { BoundedContexts } from "./internal/bounded-contexts-type";
import config from "./internal/bounded-contexts-config.json";
import { getSessionToken } from "../util/getSessionToken";
import { invalidationToMessage } from "./invalidations";
import { useCallback, useEffect, useRef, useState } from "react";

const { createServiceClient } = setupBoundedContext<BoundedContexts>(config);

export const SERVICE_HOST =
  process?.env?.REACT_APP_API_URL ?? "http://localhost:8080";

const prepareScenarioRequest = (request: ScenarioRequest) => {
  const token = getSessionToken();

  if (token === undefined) {
    return request;
  }

  return {
    ...request,
    headers: {
      ...request.headers,
      authorization: `Bearer ${token}`,
    },
  };
};

const prepareFormRequest = (request: FormRequest) => {
  const token = getSessionToken();
  if (token === undefined) {
    return request;
  }

  return {
    ...request,
    headers: {
      ...request.headers,
      authorization: `Bearer ${token}`,
    },
  };
};

const prepareViewRequest = (request: ViewRequest) => {
  const token = getSessionToken();

  if (token === undefined) {
    return request;
  }

  return {
    ...request,
    headers: {
      ...request.headers,
      authorization: `Bearer ${token}`,
    },
  };
};

export const {
  useView,
  useSingularView,
  useScenario,
  usePureScenario,
  useFormDataScenario,
  createForm,
} = createServiceClient({
  host: SERVICE_HOST,
  alias: "Sini",
  prepareScenarioRequest,
  prepareViewRequest,
  prepareFormRequest,
  translateInvalidation: invalidationToMessage,
});

// Utility function for fields that are not ment to be rendered
export const DoNotRender = (fieldname: string) => () => {
  throw new Error(
    `Do not render ${fieldname}! supply its value to initialParams instead`
  );
};

// Mock services for testing
export const {
  useView: useTestView,
  useSingularView: useTestSingularView,
  useScenario: useTestScenario,
  createForm: createTestForm,
} = createServiceClient({
  host: "http://localhost:1234/", 
  alias: "Sini",
  prepareScenarioRequest,
  prepareViewRequest,
  prepareFormRequest,
  translateInvalidation: invalidationToMessage,
});

export const useView2 = (viewName: string, initialParams: any, path: string) => {
  const [state, setState] = useState<any>({status: 'initial'});
  const paramsFromHook = useRef(initialParams);
  const pathFromHook = useRef(path);
  const token = getSessionToken();

  const loadViews = useCallback(async (params, path) => {
    try {
      setState({status: 'loading'});
      const urlParams = new URLSearchParams(params);
      const response = await fetch(`${SERVICE_HOST}/${path}?${urlParams}`, {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });
      if (response.ok) {
        const data = await response.json();
        const state = {status: 'ok', result: data};
        setState(state);
        // return state;
      } else {
        throw new Error(`${response.status} - ${response.statusText}`);
      }
    } catch (e) {
      const state = {status: 'error', error: e};
      setState(state);
      // return state;
    }
  }, [token]);

  useEffect(() => {
    loadViews(paramsFromHook.current, pathFromHook.current);
  }, [loadViews]);

  return {[viewName]: state, loadViews};
};