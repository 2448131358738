import { FC, memo, Suspense, useEffect, useMemo } from "react";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { RootRouter } from "./Router";
import { PageLoading } from "./pages/PageLoading";
import { PageGenericError } from "./pages/PageGenericError";
import { useSingularView } from "./services";
import { UserInformationContext } from "./util/useUserInformation";
import { getSessionToken } from "./util/getSessionToken";
import { Login } from "./pages/Login";
import { useUnauthorizedAction } from "./util/useUnauthorizedAction";
import { LayoutPlain } from "./layouts/LayoutPlain";

const history = createBrowserHistory({ basename: "" });

export const App: FC = memo(() => {
  const sessionToken = getSessionToken();
  useUnauthorizedAction();

  return (
    <Router history={history}>
      {sessionToken === undefined ? <Login /> : <WithSessionToken />}
    </Router>
  );
});

const WithSessionToken = () => {
  const { WhoAmI } = useSingularView("WhoAmI", {});

  const { MyCartSummary, loadView: loadMyCartSummary } = useSingularView(
    "MyCartSummary",
    {},
    {
      deferredFetch: true, // we should only fetch this view, if the user is audiologist
    }
  );

  useEffect(() => {
    if (
      WhoAmI.status === "ok" &&
      WhoAmI.result.roles.some(
        (role) => role === "audiologist" || role === "warehouse-worker"
      )
    ) {
      loadMyCartSummary();
    }
  }, [WhoAmI.status, WhoAmI.result?.roles, loadMyCartSummary]);

  const loading = useMemo(
    () => (
      <LayoutPlain>
        <PageLoading />
      </LayoutPlain>
    ),
    []
  );

  return (
    <Suspense fallback={loading}>
      {WhoAmI.status === "initial" || WhoAmI.status === "loading" ? (
        loading
      ) : MyCartSummary.status === "error" || WhoAmI.status === "error" ? (
        <LayoutPlain>
          <PageGenericError />
        </LayoutPlain>
      ) : (
        <UserInformationContext.Provider
          value={{
            isSignedOn: true,
            userKey: WhoAmI.result.userKey,
            roles: WhoAmI.result.roles,
            region: WhoAmI.result.region,
            productsInCart:
              MyCartSummary.status === "ok" ? MyCartSummary.result.quantity : 0,
            reloadCart: () => {
              loadMyCartSummary();
            },
            apiTokenSiniInvoice: WhoAmI.result.apiTokenSiniInvoice,
          }}
        >
          <RootRouter />
        </UserInformationContext.Provider>
      )}
    </Suspense>
  );
};
